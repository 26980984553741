import React from 'react';
import s from './ChatButtons.module.css';
import Button from '../Button/Button';

export default function ChatButtons({
  buttons,
  messages,
  setMessages,
  fillAnswers,
  url,
}) {
  return (
    <div className={s.buttons}>
      {buttons.map((button) => {
        return (
          <Button
            button={button.text}
            messages={messages}
            fillAnswers={fillAnswers}
            setMessages={setMessages}
            key={Math.random()}
            url={url}
          />
        );
      })}
    </div>
  );
}
