import React from 'react';
import s from './ChatHeader.module.css';
import icon from '../../images/back-icon.svg';
import { Link } from 'react-router-dom';

export default function ChatHeader() {
  return (
    <div className={s.header}>
      <Link
        className={s.link}
        to="https://cchellenic.sharepoint.com/sites/intranet-ru"
      >
        <img src={icon} alt="" className={s.img} />
        На главную
      </Link>
    </div>
  );
}
