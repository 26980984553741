import React, { useState, useEffect } from 'react';
import s from './Chat.module.css';
import axios from 'axios';
import ChatHeader from '../ChatHeader/ChatHeader';
import Messages from '../Messages/Messages';
import ChatButtons from '../ChatButtons/ChatButtons';

export default function Chat() {
  const [answers, setAnswers] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [messages, setMessages] = useState([]);
  const [session, setSession] = useState('');
  const url = 'https://cocacola-hr-bot.test.cleverbots.ru';
  useEffect(() => {
    fillAnswers('/start');
  }, []);

  const fillAnswers = (button) => {
    axios
      .post(
        `${url}/api/widget/message/`,
        {
          text: button,
          session_id: session,
        },
        { withCredentials: true }
      )
      .then((res) => {
        const sessionId = res.data.data.session_id;
        setSession(sessionId);
        const data = res.data.data.answers;
        return data;
      })
      .then((data) => {
        data.map((answer) => {
          const message = {
            text: answer.text,
            owner: 'manager',
            image: answer.image,
          };
          setMessages((messages) => [message, ...messages]);
          answer.markup &&
            answer.markup.map((data) => {
              setButtons(data);
            });
        });
      });
  };

  return (
    <div className={s.chat}>
      <ChatHeader />
      <Messages messages={messages} url={url} />
      <ChatButtons
        buttons={buttons}
        messages={messages}
        setMessages={setMessages}
        fillAnswers={fillAnswers}
        url={url}
      />
    </div>
  );
}
