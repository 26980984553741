import React, { useState, useEffect } from 'react';
import s from './Messages.module.css';
import Message from '../Message/Message';

export default function Messages({ messages, url }) {
  useEffect(() => {
    console.log(messages);
  }, [messages]);

  return (
    <div className={s.messages} id="messages">
      {messages &&
        messages.map((message) => {
          return (
            <Message
              text={message.text}
              owner={message.owner}
              key={Math.random()}
              id={Math.random()}
              image={message.image}
              url={url}
            />
          );
        })}
    </div>
  );
}
