import React, { useState, useEffect } from 'react';
import s from './Button.module.css';
import axios from 'axios';

export default function Button({
  button,
  messages,
  setMessages,
  fillAnswers,
  url,
}) {
  const addMessage = () => {
    setMessages((messages) => [{ text: button, owner: 'client' }, ...messages]);
  };
  return (
    <button
      className={s.button}
      onClick={() => {
        addMessage();
        fillAnswers(button);
      }}
    >
      {button}
    </button>
  );
}
