import React from "react";
import s from "./Header.module.css";
import logo from "../../images/logo.png";

export default function Header() {
  return (
    <header className={s.header}>
      <img src={logo} alt="" className={s.logo} />
      <h1 className={s.title}>Я - Добробот – готов тебе помочь.</h1>
    </header>
  );
}
