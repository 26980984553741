import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import s from './Message.module.css';
import logoManager from '../../images/logoManager.svg';
import logoClient from '../../images/logoClient.svg';

export default function Message({ text, owner, image, url, id }) {
  const [imgName, setImgName] = useState();

  useEffect(() => {
    image && setImgName(image.split('/').reverse()[0]);

    document.getElementById(id).innerHTML = text;
  }, []);

  return (
    <div className={owner === 'manager' ? s.messageManager : s.messageClient}>
      <img
        src={owner === 'manager' ? logoManager : logoClient}
        alt=""
        className={owner === 'manager' ? s.logoManager : s.logoClient}
      />

      <p className={s.text} id={id}></p>
      {image && (
        <Link to={url + image} className={s.text} target="_blank">
          {imgName}
        </Link>
      )}
    </div>
  );
}
